
import BaseHeader from "@/components/base/Header.vue";
import DialogsContainer from "@/components/dialog/DialogsContainer.vue";
import SnackbarsContainer from "@/components/snackbar/SnackbarsContainer.vue";
import Loader from "@/components/base/Loader.vue";
import ScrollToTop from "../components/base/ScrollToTop.vue";
import { AdminSection } from "@/types/admin";

export default {
  name: "Admin",
  components: {
    ScrollToTop,
    Loader,
    BaseHeader,
    DialogsContainer,
    SnackbarsContainer,
  },
  computed: {
    links(): AdminSection[] {
      return [
        {
          text: this.$t("resources"),
          options: [
            { to: { name: "adminCourses" }, text: this.$t("courses") },
            { to: { name: "adminLessons" }, text: this.$t("lessons") },
            { to: { name: "adminExercises" }, text: this.$t("exercises") },
            { to: { name: "adminHints" }, text: this.$t("hints") },
            { to: { name: "adminDictionary" }, text: this.$t("dictionary") },
          ],
        },
        {
          to: { name: "adminMediaLibrary" },
          text: this.$t("mediaLibrary"),
        },
        {
          text: this.$t("offer"),
          options: [{ to: { name: "adminOffersPopups" }, text: this.$t("offerPopups") }],
        },
        {
          to: { name: "adminUsers" },
          text: this.$t("users"),
        },
        {
          to: { name: "adminGiftCards" },
          text: this.$t("giftCards"),
        },
      ];
    },
  },
};
